// Shared table styles for all reports

app-delivery-reliability,
app-overview,
app-quality-report {
  .center-wrapper {
    max-width: 1300px;
    margin: 0 auto;
  }

  // Table styles
  .table-grid {
    border: 1px solid var(--ion-color-medium);

    ion-row {
      border-bottom: 1px solid var(--ion-color-medium);

      &:last-child {
        border-bottom: none;
      }
    }

    .header-row {
      background: var(--ion-color-primary);
      font-weight: bold;
      color: white;
    }

    .monthly-row {
      background: var(--ion-color-light-tint);
      cursor: pointer;

      &:hover {
        background: hsl(0, 0%, 90%);
      }

      ion-icon {
        vertical-align: middle;
        margin-right: 8px;
      }
    }

    .transfer-row {
      background: white;
    }

    .total-row {
      background: hsl(0, 0%, 90%);
      font-weight: bold;
    }

    // Column alignment styles for desktop view
    &.ion-hide-md-down {
      ion-col {
        text-align: center;
        border-right: 1px solid var(--ion-color-medium);
        padding: 8px;

        &.numeric-col {
          text-align: right;
        }

        &.align-left {
          text-align: left;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  // Mobile specific styles
  .ion-hide-md-up {
    .mobile-card {
      padding: 12px;

      div {
        margin: 8px 0;
      }
    }

    .monthly-row,
    .total-row {
      padding: 12px;
      .margin-bottom {
        margin-bottom: 8px;
      }
    }

    .transfer-row {
      border-left: 4px solid var(--ion-color-primary);
      margin: 8px 0;
    }
  }
}
